export const useCheckSequences = (
  string1: string,
  string2: string,
  sequenceLength = 4
) => {
  for (let i = 0; i < string1.length - sequenceLength + 1; i++) {
    const sequence = string1.slice(i, i + sequenceLength);

    if (string2.includes(sequence)) {
      return true;
    }
  }
  return false;
};
